* {
  margin: 0;
  padding: 0;
}

html * {
  box-sizing: border-box;
}

.App {
  font-family: sans-serif;
}

.form {
  max-width: 600px;
  max-height: 700px;
  width: 300px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  text-align: center;
  transform: translate(50%, 50%);
  position: absolute;
  right: 50%;
  bottom: 50%;
  box-shadow: 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%),
    0 2px 4px -1px rgb(0 0 0 / 20%);
}

.form .form__custom-button {
  margin-top: 50px;
}

.header {
  text-align: center;
  padding: 30px;
  color: #333;
  background: rgba(241, 241, 241, 0.8);
  position: relative;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-preferred-size: auto;
  width: 100%;
  border-radius: 6px;
}

.content {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding-top: 15px;
  padding-bottom: 15px;
}

.buttons {
  width: 100%;
}

.logo {
  width: auto;
  height: 24px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-tel-input {
  width: 80% !important;
  padding-left: 32px;
}

.react-tel-input .form-control {
  width: 100% !important;
  /* padding-left: 16px !important;
  padding-right: 16px !important;*/
}

.react-tel-input .invalid-number-message {
  position: absolute;
  z-index: 1;
  font-size: 13px;
  left: 0px !important;
  top: -16px !important;
  background: none !important;
  padding: 0 2px;
  color: #de0000;
  width: 100%;
  height: 100%;
}

.error {
  color: red;
}

.success {
  color: green;
}

/*Media Querie*/
@media only screen and (min-width: 150px) and (max-width: 530px) {
  .form {
    background: #fff;
    display: block !important;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    max-height: 100%;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {

  /* Styles */
  form {
    background: #fff;
    display: block !important;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
}

.menu-appbar {
  box-shadow: 0px 1px 8px 2px rgba(0, 0, 0, 0.16), 0px 0px 0px 2px rgba(255, 255, 255, 0.7);
}

.text-field {
  margin-top: 16px;
}

.description {
  color: #555555;
  padding-top: 16px;
  margin: 4px;
  font-size: 14px;
  line-height: 1.5;
}

.IconButton {
  margin-top: 24px;
}

.DrawerHeader {
  height: 44px;
  background-color: #DEE9EB;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
}

.HeaderStripe {
  height: 8px;
  background-color: #287E94;
}

.TitleBar {
  background-color: #eee;
  height: 40px;
  padding-left: 16px;
  padding-top: 8px;
  margin: 8px;
}

.DrawerFooter {
  position: absolute;
  bottom: 0px;
  width: 100%;

}

.Elipse {
  border-radius: 50%;
  border: 2px solid red;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
}

.privacyNote {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
}

.MuiToolbar-root {
  display: flex;
  justify-content: space-between;
  background-color:#287E94;
  overflow: auto;
}


.first-name-last-name {
  align-items: right;
  flex-direction: row;
  display: flex;
  align-items: center;
  box-sizing: unset;
}

.MuiPaper-root	{
  background-color:#287E94;
  overflow: auto;
}

.appbar-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.user-credentials {
  text-align: right;
}